module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-head/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Phil Browne | Software Engineer","short_name":"Phil Browne","start_url":"/","background_color":"#fff","theme_color":"#02aab0","display":"standalone","image":"src/images/thumbnail-img.png","icon":"src/images/favicon.png","description":"Phil Browne - Software Engineer based in Washington, DC area","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"253b9f49fdb3d469d95cdbab5cbb117e"},
    }]
